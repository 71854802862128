import { React, Page, View, Text, useState, Spinner, onUpdate } from 'lib'
import { LiveTracking } from 'actions'
import { motion } from 'types/customMotion'

import RouteFallback from './RouteFallback'
import RouteMap from './RouteMap'
import { StatusBar } from '../../components/liveTracking/StatusBar'

type LiveTrackerProps = { code: string }
type CodeStatus = 'inactive' | 'expired' | 'not found' | 'active'

function LiveTracker({ code }: LiveTrackerProps) {
  const [codeStatus, setcodeStatus] = useState<CodeStatus>(null)
  const [isLoading, setIsLoading] = useState(true)

  onUpdate(() => {
    if (codeStatus && codeStatus !== 'active') return

    const success = () => {
      setcodeStatus('active')
      setIsLoading(false)
    }
    const failure = (message: CodeStatus) => {
      setcodeStatus(message)
      setIsLoading(false)
    }

    const interval = setInterval(() => {
      LiveTracking.getRouteHistory(code, success, failure)
    }, 8000)

    return () => clearInterval(interval)
  }, [codeStatus])

  return (
    <>
      {codeStatus === 'active' ? <RouteMap/> : null}
      <motion.View
        variants={pageVariants}
        animate={codeStatus === 'active' && !isLoading ? 'dismiss' : 'init'}
        transition={{ bounce: 0.3 }}
      >
        <Page whiteBackground>
          <StatusBar status={codeStatus}/>

          <View variant={'flex column alignCenter justifyCenter'} style={styles.loadingWrapper}>
            <motion.View
              variants={errorPageVariants}
              animate={codeStatus && !isLoading ? 'show' : 'init'}
              transition={{ bounce: 0.3 }}
            >
              <RouteFallback status={codeStatus}/>
            </motion.View>
            {isLoading && (
              <View>
                <Spinner visible={isLoading}/>
                <Text variant={'p2 marginTop:2'} text={'Locating Becon User…'}/>
              </View>
            )}
          </View>
        </Page>
      </motion.View>
    </>
  )
}

export default LiveTracker

const pageVariants = {
  init: {
    opacity: 1,
    height: 'auto',
    duration: 450,
  },
  dismiss: {
    height: 0,
    opacity: 0,
    display: 'none',
  },
}

const errorPageVariants = {
  init: {
    opacity: 0,
    height: 'auto',
    display: 'none',
  },
  show: {
    opacity: 1,
    height: 'auto',
    duration: 450,
    display: 'block',
  },
}

const styles = {
  loadingWrapper: {
    minHeight: '100vh',
  },
}

import { React } from 'lib'

import LiveTrackingComponent from '../_live_tracking'

interface LiveTrackingPageProps {
  params: {
    token: string;
  }
}

function LiveTracking({ params }: LiveTrackingPageProps): JSX.Element {
  return (
    <LiveTrackingComponent code={params?.token}/>
  )
}

export default LiveTracking

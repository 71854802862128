import { onUpdate, React, useRedux, useState, useTheme } from 'lib'
import { Theme } from 'app'
import { Helmet } from 'react-helmet'
import { LiveTracking } from 'actions'

type StatusBarProps = {
  status: string
}

const colors = {
  default: Theme.colors.greyscale1,
  dark: Theme.colors.mapDark,
  light: Theme.colors.mapLight,
  alert: Theme.colors.redStatus,
}

const TRANSITION_SPEED = 50 // ms
const TRANSITION_SPEED_ALERT = 250 // ms

export const StatusBar: React.FC<StatusBarProps> = ({ status }) => {
  const { isLight } = useTheme()
  const { hasExpandedTrigger } = useRedux('Map')
  const { trigger } = LiveTracking.useTrigger()

  const isMobile = Theme.hooks.down('small')

  const [statusBarColor, setStatusBarColor] = useState<`#${any}`>(isLight ? colors.light : colors.dark)

  onUpdate(() => {
    const trackColor = !trigger?.type ? colors.alert : LiveTracking.getTriggerColor(trigger?.type)

    const newColor = hasExpandedTrigger
      ? trackColor
      : isLight ? colors.light : colors.dark

    const timeout = hasExpandedTrigger ? TRANSITION_SPEED_ALERT : TRANSITION_SPEED

    setTimeout(() => {
      setStatusBarColor(newColor)
    }, timeout)
  }, [isLight, hasExpandedTrigger])

  if (!isMobile) return null

  return (
    <Helmet>
      <meta
        name='theme-color'
        content={status !== 'active' ? colors.default : statusBarColor}
      />
    </Helmet>
  )
}
